.table table {
  width: 100%;
  color: var(--core-purple-90);
}

.table table caption {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--core-purple-90);
  margin-bottom: 5rem;
}

.table table th,
.table table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--core-purple-90);
  padding: 12px 16px;
  width: 50%;
  vertical-align: initial;
}

.table table th {
  font-weight: 700;
}

.table table tfoot {
  background-color: var(--core-purple-10);
}

.table table tfoot > * > * {
  padding: 16px;
  height: 52px;
}

.table .table-text-align-left {
  text-align: left;
}

.table .table-text-align-center {
  text-align: center;
}

.table .table-text-align-right {
  text-align: right;
}

.table .table-bordered-all,
.table .table-bordered-no-col {
  border-radius: 4px;
  border-style: hidden;
  box-shadow: 0 0 0 2px var(--core-purple-10);
}

.table .table-bordered-all > :not(caption) > * {
  border-width: 2px;
}

.table .table-bordered-all > :not(caption) > * > * {
  border-width: 0 2px;
}

.table .table-bordered-no-col > :not(caption) > * {
  border-width: 2px;
}

.table .table-bordered-no-col > :not(caption) > * > * {
  border-width: 0;
}

.table .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--core-purple-10);
}

.table .table-hover > tbody > tr:hover > * {
  background-color: var(--core-purple-20);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive-one-col tr td,
.table-responsive-one-col tr th {
  display: block;
  width: 100%;
}