.new-image-gallery {
  .image-gallery {
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
  }

  .image-gallery-content {
    @apply h-full;
  }

  /* Slides */
  .image-gallery-slides {
    @apply relative overflow-hidden sm:static sm:overflow-auto h-full;
  }

  .image-gallery-slide {
    @apply left-0 top-0 absolute w-full h-[100vw];
    
    &.center {
      @apply relative sm:absolute;
    }

    @media (min-width: 1024px) {
      @apply h-[65vw] max-h-full py-[56px];
    }
  }

  &.is-full-screen .image-gallery-slide {
    @apply h-[100vh];

    @media (min-width: 1024px) {
      @apply h-[100vh] max-h-[100vh];
    }
  }

  .video-wrapper {
    position: relative;
    background-color: transparent;

    @media (min-width: 1024px) {
      @apply h-[65vw] max-h-full; 
    }
  }

  .image-gallery-slide-wrapper {
    @apply h-full mb-0;
  }
 
  .image-gallery-swipe {
    @apply h-full max-h-[90vh];
  }

  &.is-full-screen .image-gallery-swipe {
    @apply max-h-[100vh];
  }
  
  /* Bullets */
  .image-gallery-bullets {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    position: absolute;
    bottom: 30px;
    
    z-index: 4; 
  }
  
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .image-gallery-bullets .image-gallery-bullet {
    @apply bg-black-40;
    height: 3px;
    margin: 0 2px;
    appearance: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    transition: background .2s ease-out;
  }    
  .image-gallery-bullets .image-gallery-bullet:focus,
  .image-gallery-bullets .image-gallery-bullet:hover {
    @apply bg-black-80;
  }
  
  .image-gallery-bullets .image-gallery-bullet.active {
    @apply bg-black-80;
  }
}
