.fit {
  min-height: 40vh;
  background: white !important;
}
button {
	&.btn {
		@apply py-3.5 px-2.5 h-20 text-center rounded-lg font-bold inline-block tracking-widest text-2xl text-white border-0;
		min-width: 163px;
		background-color: var(--base-color);
	  	transition: all 0.3s ease;
	}
}
button {
	&.btn:hover {
		background-color: var(--base-color-2);
		@apply text-white;
	}
}
/*You may also like slider*/
.youMaylikeSlider .slick-list {
  overflow: visible;
}
.youMaylikeSlider .slick-arrow {
  bottom: inherit;
  top: -40px;
  left: inherit;
  @apply w-24 h-10 text-xl underline normal-case shadow-none -right-0 text-base-color-2 hover:text-base-color-2 focus:outline-none;
}
.youMaylikeSlider .slick-arrow:hover, .youMaylikeSlider .slick-arrow:focus {
  @apply border-0 shadow-none outline-none text-base-color-2;
}
.youMaylikeSlider .slick-arrow:before, .youMaylikeSlider .slick-arrow:after {
  @apply hidden;
}
.youMaylikeSlider .slick-arrow.slick-next {
  @apply pr-10 right-3;
}
.youMaylikeSlider .slick-arrow.slick-prev {
  @apply pl-10 right-32;
}
/*About page style*/
#why-create-inner .slick-list {
  overflow: visible;
}
#why-create-inner .slick-track {
  @apply flex;
}
#why-create-inner .slick-track .slick-slide{
  @apply h-auto;
}
#why-create-inner .slick-track .slick-slide > div {
  @apply h-full;
}
#vimeo_video_container_content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
#vimeo_video_container_content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*Button Common Style*/
.btn {
  @apply py-3.5 px-2.5 h-12 text-center rounded-md font-bold inline-block tracking-widest text-sm text-white uppercase cursor-pointer;
  min-width: 163px;
  background-color: var(--base-color);
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: #f9677d;
}

/*Slick Dosts*/
.slick-slider {
  @apply pb-8;
}
.slick-slider .slick-dots li {
  @apply h-2.5 w-2.5;
}
.slick-slider .slick-dots li button {
  @apply h-2.5 w-2.5 rounded-full;
  background: #D8D8D8;
}
.slick-slider .slick-dots li.slick-active button {
  background: var(--base-color);
}
.slick-slider .slick-dots li button:before {
  @apply hidden;
}


.howItWorkSlider .slick-arrow {
  background-color: var(--base-color-2) !important;
  background-size: 14px !important;
  @apply w-20 h-20 rounded-full;
}
.howItWorkSlider .slick-slider {
  @apply p-0;
}
.howItWorkSlider .slick-arrow:after, .howItWorkSlider .slick-arrow:before {
  @apply hidden;
}
.howItWorkSlider .slick-arrow.slick-next {
  @apply -right-8;
  @screen xl {
    right: -60px;
  }
}
.howItWorkSlider .slick-arrow.slick-prev {
  @apply transform rotate-180 -translate-y-2/4 -left-8;
  @screen xl {
    left: -60px;
  }
}
.howItWorkSlider .slick-dots {

}
.howItWorkSlider .slick-slider .slick-dots li button {
  @apply opacity-50 bg-base-color-2;
}
.howItWorkSlider .slick-slider .slick-dots li.slick-active button {
  @apply opacity-100;
}

.span1 {
  @apply w-full;
  @screen md {
    width: inherit;
  }
}

.span2 {
  @apply w-full;
  @screen md {
    width: 49%;
  }
}

.span4 {
   @apply w-full;
}

.span2+.span2 .dropDown {
  @apply w-6/12;
  min-width: inherit;
}

.span2+.span2 {
    @apply flex flex-row flex-wrap justify-between;
}


.span2+.span2 .dropdownOption {
    @apply w-6/12;
    min-width: inherit;
}

.span3 {
  @apply w-full;
  @screen md {
    width: calc(60% - 10px);
  }
}
.span3 + .span1 .dropdownOption {
  @screen md {
    min-width: auto;
  }
}
.span3 + .span1 {
  @screen md {
    width: 40%;
  }
}

.noOption .selectedOption {
  @apply pl-0 shadow-none cursor-default;
}
.noOption .selectedOption:after {
  @apply hidden;
}
.noOption .dropDownLabel:after {
  content: ":";
}
.ns-mobile-main {
    max-height: inherit !important;
    overflow-y: inherit !important;
}

.ns-mobile-in {
  background: #F0F0F6;
}

.ns-mob-submenu {
  position: relative;
}
.ns-mob-submenu > img {
  position: absolute;
  top: 1.3rem;
  left: 1.5rem;
  margin: auto;
  max-width: 35px;
  max-height: 25px;
}
.ns-mob-submenu > span {
  padding-left: 4rem;
}

.mobile-menu-scroll {
  height: calc(100% - 62px);
  overflow-y: auto;
  top: 62px;
}

.mobile-menu-scroll--announcement{
  height: calc(100% - 88px);
  overflow-y: auto;
  top: 88px;
}

.mob-mm-cta {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 9999px;
    padding: 0.5rem;
    flex-grow: 0;
    background-color: #ecf4f9;
}

.mob-mm-cta p {
    color: var(--base-color-2);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-transform: none;
}

.mob-mm-cta p  a {
  text-decoration: underline;
  font-weight: 700;
}

.mob-sample-box {
  width: calc(50% - 0.5rem);
  height: auto !important;
  flex: 0 0 50%;
  text-align: center;
}

.mob-sample-box span {
    font-size: 13px;
    text-transform: none;
    text-align: left;
}

button {
	&.btn {
		@apply py-3.5 px-2.5 h-20 text-center rounded-lg font-bold inline-block tracking-widest text-2xl text-white border-0;
		min-width: 163px;
		background-color: var(--base-color);
	  	transition: all 0.3s ease;
	}
}
button {
	&.btn:hover {
		background-color: var(--base-color-2);
		@apply text-white;
	}
}
/*You may also like slider*/
.youMaylikeSlider .slick-list {
  overflow: visible;
}
.youMaylikeSlider .slick-arrow {
  bottom: inherit;
  top: -40px;
  left: inherit;
  @apply w-24 h-10 text-xl underline normal-case shadow-none -right-0 text-base-color-2 hover:text-base-color-2 focus:outline-none;
}
.youMaylikeSlider .slick-arrow:hover, .youMaylikeSlider .slick-arrow:focus {
  @apply border-0 shadow-none outline-none text-base-color-2;
}
.youMaylikeSlider .slick-arrow:before, .youMaylikeSlider .slick-arrow:after {
  @apply hidden;
}
.youMaylikeSlider .slick-arrow.slick-next {
  @apply pr-10 right-3;
}
.youMaylikeSlider .slick-arrow.slick-prev {
  @apply pl-10 right-32;
}
/*About page style*/
#why-create-inner .slick-list {
  overflow: visible;
}
#why-create-inner .slick-track {
  @apply flex;
}
#why-create-inner .slick-track .slick-slide{
  @apply h-auto;
}
#why-create-inner .slick-track .slick-slide > div {
  @apply h-full;
}
#vimeo_video_container_content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
#vimeo_video_container_content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*Button Common Style*/
.btn {
  @apply py-3.5 px-2.5 h-12 text-center rounded-md font-bold inline-block tracking-widest text-sm text-white uppercase cursor-pointer;
  min-width: 163px;
  background-color: var(--base-color);
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: #f9677d;
}

/*Slick Dosts*/
.slick-slider {
  @apply pb-8;
}
.slick-slider .slick-dots li {
  @apply h-2.5 w-2.5;
}
.slick-slider .slick-dots li button {
  @apply h-2.5 w-2.5 rounded-full;
  background: #D8D8D8;
}
.slick-slider .slick-dots li.slick-active button {
  background: var(--base-color);
}
.slick-slider .slick-dots li button:before {
  @apply hidden;
}


.howItWorkSlider .slick-arrow {
  background-color: var(--base-color-2) !important;
  background-size: 14px !important;
  @apply w-20 h-20 rounded-full;
}
.howItWorkSlider .slick-slider {
  @apply p-0;
}
.howItWorkSlider .slick-arrow:after, .howItWorkSlider .slick-arrow:before {
  @apply hidden;
}
.howItWorkSlider .slick-arrow.slick-next {
  @apply -right-8;
  @screen xl {
    right: -60px;
  }
}
.howItWorkSlider .slick-arrow.slick-prev {
  @apply transform rotate-180 -translate-y-2/4 -left-8;
  @screen xl {
    left: -60px;
  }
}
.howItWorkSlider .slick-dots {

}
.howItWorkSlider .slick-slider .slick-dots li button {
  @apply opacity-50 bg-base-color-2;
}
.howItWorkSlider .slick-slider .slick-dots li.slick-active button {
  @apply opacity-100;
}

.span1 {
  @apply w-full;
  @screen md {
    width: inherit;
  }
}

.span2 {
  @apply w-full;
  @screen md {
    width: 49%;
  }
}

.span4 {
   @apply w-full;
}

.span2+.span2 .dropDown {
  @apply w-6/12;
  min-width: inherit;
}

.span2+.span2 {
    @apply flex flex-row flex-wrap justify-between;
}


.span2+.span2 .dropdownOption {
    @apply w-6/12;
    min-width: inherit;
}

.span3 {
  @apply w-full;
  @screen md {
    width: calc(60% - 10px);
  }
}
.span3 + .span1 .dropdownOption {
  @screen md {
    min-width: auto;
  }
}
.span3 + .span1 {
  @screen md {
    width: 40%;
  }
}
/*Dot Convert in scroll CSS*/
.productSliderScrollbar .slick-dots {
  justify-content: center;
  @apply justify-center px-10 -bottom-24 lg:px-0;
  @screen md {
    display: flex !important;
  }
}
.productSliderScrollbar .slick-slider {
  @apply pb-0 mb-24;
}
.productSliderScrollbar .slick-dots li {
  @apply mx-2 md:m-0 md:w-auto md:h-auto md:w-full;
  @screen md {
    width: 150px;
  }
}
.productSliderScrollbar .slick-dots li button {
  @apply md:rounded-none md:w-full md:h-1.5 md:bg-white md:p-0;
  @screen md {
    margin: 0 !important;
  }
}
.productSliderScrollbar .slick-dots li.slick-active button {
  @apply md:bg-base-color-2;
}

.productSliderScrollbar .slick-arrow {
  @apply z-20 w-12 h-12 bg-white rounded-full md:w-28 md:h-28;
  box-shadow: 0 2px 3px 0 #c2c2c9;
}
.productSliderScrollbar .slick-arrow:visited, .productSliderScrollbar .slick-arrow:focus, .noissueBrandSlider .slick-arrow:focus, .noissueBrandSlider .slick-arrow:visited {
  @apply bg-white;
}
.productSliderScrollbar .slick-arrow:hover {
  @apply bg-base-color-2;
}

.productSliderScrollbar .slick-arrow.slick-next {
  right: 10px;
  @screen md {
    right: 30px;
  }
  @screen lg {
    right: 27%;
  }
}
.productSliderScrollbar .slick-arrow.slick-next:before {
  @apply absolute block m-auto -top-0 -right-0 -left-0;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  transform: rotate(-45deg);
  opacity: 1;
  width: 1px;
  height: 6px;
  @screen md {
    width: 4px;
    height: 20px;
    bottom: 15px;
  }
}
.productSliderScrollbar .slick-arrow.slick-next:after {
  @apply absolute block m-auto -bottom-0 -right-0 -left-0;
  background: var(--base-color-2);
  top: 4px;
  content: "";
  font-size: 0;
  transform: rotate(45deg);
  width: 1px;
  height: 6px;
  @screen md {
    width: 4px;
    height: 20px;
    top: 10px;
  }
}
.productSliderScrollbar .slick-arrow.slick-prev {
  left: 10px;
  @screen md {
    left: 30px;
  }
  @screen lg {
    left: 27%;
  }
}
.productSliderScrollbar .slick-arrow.slick-prev:before {
  @apply absolute block m-auto -top-0 -right-0 -left-0;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  transform: rotate(45deg);
  opacity: 1;
  width: 1px;
  height: 6px;
  @screen md {
    width: 4px;
    height: 20px;
    bottom: 15px;
  }
}
.productSliderScrollbar .slick-arrow.slick-prev:after {
  @apply absolute block m-auto -bottom-0 -right-0 -left-0;
  background: var(--base-color-2);
  content: "";
  font-size: 0;
  top: 4px;
  transform: rotate(-45deg);
  width: 1px;
  height: 6px;
  @screen md {
    width: 4px;
    height: 20px;
    top: 10px;
  }
}
.productSliderScrollbar .slick-arrow:hover:before, .productSliderScrollbar .slick-arrow:hover:after {
  @apply bg-white;
}
.productSliderScrollbar .slick-slide:not(.slick-current) .prdRangeContentInner {
  opacity: 0;
  visibility: hidden;
}
.noissueBrandSlider .slick-slider {
  @apply p-0;
}
.noissueBrandSlider .slick-arrow {
  @apply w-12 h-12 bg-white rounded-full md:w-14 md:h-14;
  box-shadow: 0 2px 3px 0 #c2c2c9;
}
.noissueBrandSlider .slick-arrow:hover {
  @apply bg-base-color-2;
}
.noissueBrandSlider .slick-arrow.slick-prev {
  left: -40px;
}
.noissueBrandSlider .slick-arrow.slick-next {
  right: -40px;
}

.noissueBrandSlider .slick-arrow.slick-prev:before, .noissueBrandSlider .slick-arrow.slick-prev:after {
  content: "";
  @apply absolute block m-auto -top-0 -right-0 -left-0;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  width: 1px;
  height: 6px;
  transform: rotate(45deg);
  opacity: 1;
  @screen md {
    width: 2px;
    height: 10px;
    bottom: 6px;
  }
}
.noissueBrandSlider .slick-arrow.slick-prev:after{
  transform: rotate(-45deg);
  top: 4px;
  bottom: 0;
  @screen md {
    top: 6px;
  }
}
.noissueBrandSlider .slick-arrow.slick-next:before, .noissueBrandSlider .slick-arrow.slick-next:after {
  content: "";
  @apply absolute block m-auto -top-0 -right-0 -left-0;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  width: 1px;
  height: 6px;
  transform: rotate(-45deg);
  opacity: 1;
  @screen md {
    width: 2px;
    height: 10px;
    bottom: 6px;
  }
}

.noissueBrandSlider .slick-arrow.slick-next:after{
  transform: rotate(45deg);
  top: 4px;
  bottom: 0;
  @screen md {
    top: 6px;
  }
}

.noissueBrandSlider .slick-arrow:hover:before, .noissueBrandSlider .slick-arrow:hover:after {
  @apply bg-white;
}
.remove-margn .slick-slider .slick-track {
  margin-top: 10px !important;
}
.image-box-col-temp .image * {
    margin: 0 !important;
}

html.mobileMenuOpen {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
}
@screen md {
  html.mobileMenuOpen {
    position: unset;
    overflow: unset;
  }
}


html.mobileMenuOpen .navBarMobileSection {
  /* position: fixed; */
  width: 100%;
}

#main_add_cart_button[disabled] {
  opacity: 0.65;
}.v3_main_cls{
  padding: 100px 0;
}

.editor-area-wrappper .receive-msg {
  border-radius: 0.5rem;
  min-height: 9rem;
  max-width: 36rem;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: transparent;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-area-wrappper .receive-msg img {
  margin-bottom: 10px;
  width: 106px;
}
.editor-area-wrappper .receive-msg p {
  @apply text-base-color-2;
  line-height: 1.3;
  font-size: 1.1rem;
  font-weight: 600;
  max-width: 200px;
  margin: auto;
}
.editor-area-wrappper .embosser.receive-msg p {
  line-height: 1.3;
  font-size: 1.4rem;
  margin-top: 1.5rem;
}

.editor-area-wrappper .embosser.receive-msg img {
  width: 100px;
}

.editor-area-wrappper .boxes.receive-msg p {
  line-height: 1.4;
  font-size: 1.6rem;
  max-width: 28rem;
}

.editor-area-wrappper .boxes.receive-msg img {
  width: 75px;
}

.editor-area-wrappper .hang-tag.receive-msg p {
  line-height: 1.3;
  font-size: 1.4rem;
  margin-top: 1.5rem;
}

.editor-area-wrappper .hang-tag.receive-msg.ractangle p {
  line-height: 1.4;
  font-size: 1.4rem;
  max-width: 10.5rem;
}

.editor-area-wrappper .hang-tag.receive-msg img {
  width: 80px;
}

.editor-area-wrappper .drawstring.receive-msg p {
  line-height: 1.4;
  font-size: 1.2rem;
  max-width: 18rem;
}

.editor-area-wrappper .receive-msg.small-cls {
  max-width: 190px;
  padding: 0px 10px 10px;
  min-height: 46px;
  margin-top: 20px;
  background-color: transparent;

  @media (max-width: 768px) {
    max-width: 160px;
  }
}
.editor-area-wrappper .receive-msg.small-cls img {
  width: 36px;
}
.editor-area-wrappper .receive-msg.small-cls p {
  font-size: 0.8rem;
  line-height: 1.1;
  margin-top: 0;
  max-width: 110px;
}

.editor-area-wrappper .receive-msg.washi-tape img {
  width: 36px;
}
.editor-area-wrappper .receive-msg.washi-tape p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-top: 0;
  max-width: 30rem;
}

.notification-label {
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  font-size: 12px;
  padding: 5px;
  line-height: 1.4;
}

@media (max-width: 1100px) {
  .notification-label {
    font-size: 10px;
    padding: 10px;
  }
}

@media (max-width: 1023px) {
  .notification-label {
    font-size: 12px;
    padding: 10px;
  }
}


.notification-label .title-text {
  color: #fd516e;
  font-weight: 700;
  display: inline;
}

.notification-label span {
  text-decoration: underline;
  color: #fd516e;
  font-weight: 700;
  cursor: pointer;
}

.notification-label span:hover {
  text-decoration: none;
}


.joinButton {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  height: 50px;
  padding: 15px;
}
.joinButton:hover {
  background-color: var(--base-color-2);
  color: var(--white);
}

