.table table {
  width: 100%;
  color: var(--core-purple-90);
}

.table table caption {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--core-purple-90);
  margin-bottom: 5rem;
}

.table table th,
.table table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--core-purple-90);
  padding: 12px 16px;
  width: 50%;
  vertical-align: initial;
}

.table table th {
  font-weight: 700;
}

.table table tfoot {
  background-color: var(--core-purple-10);
}

.table table tfoot > * > * {
  padding: 16px;
  height: 52px;
}

.table .table-text-align-left {
  text-align: left;
}

.table .table-text-align-center {
  text-align: center;
}

.table .table-text-align-right {
  text-align: right;
}

.table .table-bordered-all,
.table .table-bordered-no-col {
  border-radius: 4px;
  border-style: hidden;
  box-shadow: 0 0 0 2px var(--core-purple-10);
}

.table .table-bordered-all > :not(caption) > * {
  border-width: 2px;
}

.table .table-bordered-all > :not(caption) > * > * {
  border-width: 0 2px;
}

.table .table-bordered-no-col > :not(caption) > * {
  border-width: 2px;
}

.table .table-bordered-no-col > :not(caption) > * > * {
  border-width: 0;
}

.table .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--core-purple-10);
}

.table .table-hover > tbody > tr:hover > * {
  background-color: var(--core-purple-20);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive-one-col tr td,
.table-responsive-one-col tr th {
  display: block;
  width: 100%;
}
@font-face {
font-family: '__PPMori_de6f22';
src: url(/_next/static/media/b106d8fe4a084bdc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__PPMori_de6f22';
src: url(/_next/static/media/ab48fc0ef850de33-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: semi-bold;
}

@font-face {
font-family: '__PPMori_de6f22';
src: url(/_next/static/media/d93b1ccac6a8e61f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: bold;
}@font-face {font-family: '__PPMori_Fallback_de6f22';src: local("Arial");ascent-override: 70.87%;descent-override: 18.90%;line-gap-override: 9.45%;size-adjust: 105.82%
}.__className_de6f22 {font-family: '__PPMori_de6f22', '__PPMori_Fallback_de6f22'
}.__variable_de6f22 {--font-mori: '__PPMori_de6f22', '__PPMori_Fallback_de6f22'
}

.fit {
  min-height: 40vh;
  background: white !important;
}
button.btn {
  display: inline-block;
  height: 5rem;
  border-radius: 0.5rem;
  border-width: 0px;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--white);
		min-width: 163px;
		background-color: var(--base-color);
	  	transition: all 0.3s ease;
}
button.btn:hover {
		background-color: var(--base-color-2);
		color: var(--white);
	}
/*You may also like slider*/
.youMaylikeSlider .slick-list {
  overflow: visible;
}
.youMaylikeSlider .slick-arrow {
  bottom: inherit;
  top: -40px;
  left: inherit;
  right: -0px;
  height: 2.5rem;
  width: 6rem;
  font-size: 1.4rem;
  line-height: 1.55;
  text-transform: none;
  color: var(--base-color-2);
  text-decoration-line: underline;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.youMaylikeSlider .slick-arrow:hover {
  color: var(--base-color-2);
}
.youMaylikeSlider .slick-arrow:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.youMaylikeSlider .slick-arrow:hover, .youMaylikeSlider .slick-arrow:focus {
  border-width: 0px;
  color: var(--base-color-2);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.youMaylikeSlider .slick-arrow:before, .youMaylikeSlider .slick-arrow:after {
  display: none;
}
.youMaylikeSlider .slick-arrow.slick-next {
  right: 0.75rem;
  padding-right: 2.5rem;
}
.youMaylikeSlider .slick-arrow.slick-prev {
  right: 8rem;
  padding-left: 2.5rem;
}
/*About page style*/
#why-create-inner .slick-list {
  overflow: visible;
}
#why-create-inner .slick-track {
  display: flex;
}
#why-create-inner .slick-track .slick-slide {
  height: auto;
}
#why-create-inner .slick-track .slick-slide > div {
  height: 100%;
}
#vimeo_video_container_content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
#vimeo_video_container_content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*Button Common Style*/
.btn {
  display: inline-block;
  height: 3rem;
  cursor: pointer;
  border-radius: 0.375rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--white);
  min-width: 163px;
  background-color: var(--base-color);
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: #f9677d;
}

/*Slick Dosts*/
.slick-slider {
  padding-bottom: 2rem;
}
.slick-slider .slick-dots li {
  height: 0.625rem;
  width: 0.625rem;
}
.slick-slider .slick-dots li button {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 9999px;
  background: #D8D8D8;
}
.slick-slider .slick-dots li.slick-active button {
  background: var(--base-color);
}
.slick-slider .slick-dots li button:before {
  display: none;
}


.howItWorkSlider .slick-arrow {
  background-color: var(--base-color-2) !important;
  background-size: 14px !important;
  height: 5rem;
  width: 5rem;
  border-radius: 9999px;
}
.howItWorkSlider .slick-slider {
  padding: 0px;
}
.howItWorkSlider .slick-arrow:after, .howItWorkSlider .slick-arrow:before {
  display: none;
}
.howItWorkSlider .slick-arrow.slick-next {
  right: -2rem;
}
@media (min-width: 1280px) {
.howItWorkSlider .slick-arrow.slick-next {
    right: -60px
}
  }
.howItWorkSlider .slick-arrow.slick-prev {
  left: -2rem;
  --tw-translate-y: -50%;
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 1280px) {
.howItWorkSlider .slick-arrow.slick-prev {
    left: -60px
}
  }
.howItWorkSlider .slick-dots {

}
.howItWorkSlider .slick-slider .slick-dots li button {
  background-color: var(--base-color-2);
  opacity: 0.5;
}
.howItWorkSlider .slick-slider .slick-dots li.slick-active button {
  opacity: 1;
}

.span1 {
  width: 100%;
}

@media (min-width: 768px) {

.span1 {
    width: inherit
}
  }

.span2 {
  width: 100%;
}

@media (min-width: 768px) {

.span2 {
    width: 49%
}
  }

.span4 {
  width: 100%;
}

.span2+.span2 .dropDown {
  width: 50%;
  min-width: inherit;
}

.span2+.span2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}


.span2+.span2 .dropdownOption {
  width: 50%;
    min-width: inherit;
}

.span3 {
  width: 100%;
}

@media (min-width: 768px) {

.span3 {
    width: calc(60% - 10px)
}
.span3 + .span1 .dropdownOption {
    min-width: auto
}
.span3 + .span1 {
    width: 40%
}
  }

.noOption .selectedOption {
  cursor: default;
  padding-left: 0px;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.noOption .selectedOption:after {
  display: none;
}
.noOption .dropDownLabel:after {
  content: ":";
}
.ns-mobile-main {
    max-height: inherit !important;
    overflow-y: inherit !important;
}

.ns-mobile-in {
  background: #F0F0F6;
}

.ns-mob-submenu {
  position: relative;
}
.ns-mob-submenu > img {
  position: absolute;
  top: 1.3rem;
  left: 1.5rem;
  margin: auto;
  max-width: 35px;
  max-height: 25px;
}
.ns-mob-submenu > span {
  padding-left: 4rem;
}

.mobile-menu-scroll {
  height: calc(100% - 62px);
  overflow-y: auto;
  top: 62px;
}

.mobile-menu-scroll--announcement{
  height: calc(100% - 88px);
  overflow-y: auto;
  top: 88px;
}

.mob-mm-cta {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 9999px;
    padding: 0.5rem;
    flex-grow: 0;
    background-color: #ecf4f9;
}

.mob-mm-cta p {
    color: var(--base-color-2);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-transform: none;
}

.mob-mm-cta p  a {
  text-decoration: underline;
  font-weight: 700;
}

.mob-sample-box {
  width: calc(50% - 0.5rem);
  height: auto !important;
  flex: 0 0 50%;
  text-align: center;
}

.mob-sample-box span {
    font-size: 13px;
    text-transform: none;
    text-align: left;
}

button.btn {
  display: inline-block;
  height: 5rem;
  border-radius: 0.5rem;
  border-width: 0px;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: var(--white);
		min-width: 163px;
		background-color: var(--base-color);
	  	transition: all 0.3s ease;
}
button.btn:hover {
		background-color: var(--base-color-2);
		color: var(--white);
	}
/*You may also like slider*/
.youMaylikeSlider .slick-list {
  overflow: visible;
}
.youMaylikeSlider .slick-arrow {
  bottom: inherit;
  top: -40px;
  left: inherit;
  right: -0px;
  height: 2.5rem;
  width: 6rem;
  font-size: 1.4rem;
  line-height: 1.55;
  text-transform: none;
  color: var(--base-color-2);
  text-decoration-line: underline;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.youMaylikeSlider .slick-arrow:hover {
  color: var(--base-color-2);
}
.youMaylikeSlider .slick-arrow:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.youMaylikeSlider .slick-arrow:hover, .youMaylikeSlider .slick-arrow:focus {
  border-width: 0px;
  color: var(--base-color-2);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.youMaylikeSlider .slick-arrow:before, .youMaylikeSlider .slick-arrow:after {
  display: none;
}
.youMaylikeSlider .slick-arrow.slick-next {
  right: 0.75rem;
  padding-right: 2.5rem;
}
.youMaylikeSlider .slick-arrow.slick-prev {
  right: 8rem;
  padding-left: 2.5rem;
}
/*About page style*/
#why-create-inner .slick-list {
  overflow: visible;
}
#why-create-inner .slick-track {
  display: flex;
}
#why-create-inner .slick-track .slick-slide {
  height: auto;
}
#why-create-inner .slick-track .slick-slide > div {
  height: 100%;
}
#vimeo_video_container_content {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
#vimeo_video_container_content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*Button Common Style*/
.btn {
  display: inline-block;
  height: 3rem;
  cursor: pointer;
  border-radius: 0.375rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--white);
  min-width: 163px;
  background-color: var(--base-color);
  transition: all 0.3s ease;
}
.btn:hover {
  background-color: #f9677d;
}

/*Slick Dosts*/
.slick-slider {
  padding-bottom: 2rem;
}
.slick-slider .slick-dots li {
  height: 0.625rem;
  width: 0.625rem;
}
.slick-slider .slick-dots li button {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 9999px;
  background: #D8D8D8;
}
.slick-slider .slick-dots li.slick-active button {
  background: var(--base-color);
}
.slick-slider .slick-dots li button:before {
  display: none;
}


.howItWorkSlider .slick-arrow {
  background-color: var(--base-color-2) !important;
  background-size: 14px !important;
  height: 5rem;
  width: 5rem;
  border-radius: 9999px;
}
.howItWorkSlider .slick-slider {
  padding: 0px;
}
.howItWorkSlider .slick-arrow:after, .howItWorkSlider .slick-arrow:before {
  display: none;
}
.howItWorkSlider .slick-arrow.slick-next {
  right: -2rem;
}
@media (min-width: 1280px) {
.howItWorkSlider .slick-arrow.slick-next {
    right: -60px
}
  }
.howItWorkSlider .slick-arrow.slick-prev {
  left: -2rem;
  --tw-translate-y: -50%;
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 1280px) {
.howItWorkSlider .slick-arrow.slick-prev {
    left: -60px
}
  }
.howItWorkSlider .slick-dots {

}
.howItWorkSlider .slick-slider .slick-dots li button {
  background-color: var(--base-color-2);
  opacity: 0.5;
}
.howItWorkSlider .slick-slider .slick-dots li.slick-active button {
  opacity: 1;
}

.span1 {
  width: 100%;
}

@media (min-width: 768px) {

.span1 {
    width: inherit
}
  }

.span2 {
  width: 100%;
}

@media (min-width: 768px) {

.span2 {
    width: 49%
}
  }

.span4 {
  width: 100%;
}

.span2+.span2 .dropDown {
  width: 50%;
  min-width: inherit;
}

.span2+.span2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}


.span2+.span2 .dropdownOption {
  width: 50%;
    min-width: inherit;
}

.span3 {
  width: 100%;
}

@media (min-width: 768px) {

.span3 {
    width: calc(60% - 10px)
}
.span3 + .span1 .dropdownOption {
    min-width: auto
}
.span3 + .span1 {
    width: 40%
}
  }
/*Dot Convert in scroll CSS*/
.productSliderScrollbar .slick-dots {
  bottom: -6rem;
  justify-content: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
@media (min-width: 1024px) {
  .productSliderScrollbar .slick-dots {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 768px) {
.productSliderScrollbar .slick-dots {
    display: flex !important
}
  }
.productSliderScrollbar .slick-slider {
  margin-bottom: 6rem;
  padding-bottom: 0px;
}
.productSliderScrollbar .slick-dots li {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (min-width: 768px) {
  .productSliderScrollbar .slick-dots li {
    margin: 0px;
    height: auto;
    width: auto;
    width: 100%;
    width: 150px;
  }
.productSliderScrollbar .slick-dots li button {
    height: 0.375rem;
    width: 100%;
    border-radius: 0px;
    background-color: var(--white);
    padding: 0px;
    margin: 0 !important;
  }
.productSliderScrollbar .slick-dots li.slick-active button {
    background-color: var(--base-color-2);
  }
}

.productSliderScrollbar .slick-arrow {
  z-index: 20;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  background-color: var(--white);
}

@media (min-width: 768px) {
  .productSliderScrollbar .slick-arrow {
    height: 7rem;
    width: 7rem;
  }
}

.productSliderScrollbar .slick-arrow {
  box-shadow: 0 2px 3px 0 #c2c2c9;
}
.productSliderScrollbar .slick-arrow:visited, .productSliderScrollbar .slick-arrow:focus, .noissueBrandSlider .slick-arrow:focus, .noissueBrandSlider .slick-arrow:visited {
  background-color: var(--white);
}
.productSliderScrollbar .slick-arrow:hover {
  background-color: var(--base-color-2);
}

.productSliderScrollbar .slick-arrow.slick-next {
  right: 10px;
}

@media (min-width: 768px) {

.productSliderScrollbar .slick-arrow.slick-next {
    right: 30px
}
  }

@media (min-width: 1024px) {

.productSliderScrollbar .slick-arrow.slick-next {
    right: 27%
}
  }
.productSliderScrollbar .slick-arrow.slick-next:before {
  position: absolute;
  top: -0px;
  right: -0px;
  left: -0px;
  margin: auto;
  display: block;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  transform: rotate(-45deg);
  opacity: 1;
  width: 1px;
  height: 6px;
}
@media (min-width: 768px) {
.productSliderScrollbar .slick-arrow.slick-next:before {
    width: 4px;
    height: 20px;
    bottom: 15px
}
  }
.productSliderScrollbar .slick-arrow.slick-next:after {
  position: absolute;
  bottom: -0px;
  right: -0px;
  left: -0px;
  margin: auto;
  display: block;
  background: var(--base-color-2);
  top: 4px;
  content: "";
  font-size: 0;
  transform: rotate(45deg);
  width: 1px;
  height: 6px;
}
@media (min-width: 768px) {
.productSliderScrollbar .slick-arrow.slick-next:after {
    width: 4px;
    height: 20px;
    top: 10px
}
  }
.productSliderScrollbar .slick-arrow.slick-prev {
  left: 10px;
}
@media (min-width: 768px) {
.productSliderScrollbar .slick-arrow.slick-prev {
    left: 30px
}
  }
@media (min-width: 1024px) {
.productSliderScrollbar .slick-arrow.slick-prev {
    left: 27%
}
  }
.productSliderScrollbar .slick-arrow.slick-prev:before {
  position: absolute;
  top: -0px;
  right: -0px;
  left: -0px;
  margin: auto;
  display: block;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  transform: rotate(45deg);
  opacity: 1;
  width: 1px;
  height: 6px;
}
@media (min-width: 768px) {
.productSliderScrollbar .slick-arrow.slick-prev:before {
    width: 4px;
    height: 20px;
    bottom: 15px
}
  }
.productSliderScrollbar .slick-arrow.slick-prev:after {
  position: absolute;
  bottom: -0px;
  right: -0px;
  left: -0px;
  margin: auto;
  display: block;
  background: var(--base-color-2);
  content: "";
  font-size: 0;
  top: 4px;
  transform: rotate(-45deg);
  width: 1px;
  height: 6px;
}
@media (min-width: 768px) {
.productSliderScrollbar .slick-arrow.slick-prev:after {
    width: 4px;
    height: 20px;
    top: 10px
}
  }
.productSliderScrollbar .slick-arrow:hover:before, .productSliderScrollbar .slick-arrow:hover:after {
  background-color: var(--white);
}
.productSliderScrollbar .slick-slide:not(.slick-current) .prdRangeContentInner {
  opacity: 0;
  visibility: hidden;
}
.noissueBrandSlider .slick-slider {
  padding: 0px;
}
.noissueBrandSlider .slick-arrow {
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  background-color: var(--white);
}
@media (min-width: 768px) {
  .noissueBrandSlider .slick-arrow {
    height: 3.5rem;
    width: 3.5rem;
  }
}
.noissueBrandSlider .slick-arrow {
  box-shadow: 0 2px 3px 0 #c2c2c9;
}
.noissueBrandSlider .slick-arrow:hover {
  background-color: var(--base-color-2);
}
.noissueBrandSlider .slick-arrow.slick-prev {
  left: -40px;
}
.noissueBrandSlider .slick-arrow.slick-next {
  right: -40px;
}

.noissueBrandSlider .slick-arrow.slick-prev:before, .noissueBrandSlider .slick-arrow.slick-prev:after {
  content: "";
  position: absolute;
  top: -0px;
  right: -0px;
  left: -0px;
  margin: auto;
  display: block;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  width: 1px;
  height: 6px;
  transform: rotate(45deg);
  opacity: 1;
}

@media (min-width: 768px) {

.noissueBrandSlider .slick-arrow.slick-prev:before, .noissueBrandSlider .slick-arrow.slick-prev:after {
    width: 2px;
    height: 10px;
    bottom: 6px
}
  }
.noissueBrandSlider .slick-arrow.slick-prev:after{
  transform: rotate(-45deg);
  top: 4px;
  bottom: 0;
}
@media (min-width: 768px) {
.noissueBrandSlider .slick-arrow.slick-prev:after{
    top: 6px
}
  }
.noissueBrandSlider .slick-arrow.slick-next:before, .noissueBrandSlider .slick-arrow.slick-next:after {
  content: "";
  position: absolute;
  top: -0px;
  right: -0px;
  left: -0px;
  margin: auto;
  display: block;
  background: var(--base-color-2);
  bottom: 4px;
  font-size: 0;
  width: 1px;
  height: 6px;
  transform: rotate(-45deg);
  opacity: 1;
}
@media (min-width: 768px) {
.noissueBrandSlider .slick-arrow.slick-next:before, .noissueBrandSlider .slick-arrow.slick-next:after {
    width: 2px;
    height: 10px;
    bottom: 6px
}
  }

.noissueBrandSlider .slick-arrow.slick-next:after{
  transform: rotate(45deg);
  top: 4px;
  bottom: 0;
}

@media (min-width: 768px) {

.noissueBrandSlider .slick-arrow.slick-next:after{
    top: 6px
}
  }

.noissueBrandSlider .slick-arrow:hover:before, .noissueBrandSlider .slick-arrow:hover:after {
  background-color: var(--white);
}
.remove-margn .slick-slider .slick-track {
  margin-top: 10px !important;
}
.image-box-col-temp .image * {
    margin: 0 !important;
}

html.mobileMenuOpen {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
}
@media (min-width: 768px) {
  html.mobileMenuOpen {
    position: unset;
    overflow: unset;
  }
}


html.mobileMenuOpen .navBarMobileSection {
  /* position: fixed; */
  width: 100%;
}

#main_add_cart_button[disabled] {
  opacity: 0.65;
}.v3_main_cls{
  padding: 100px 0;
}

.editor-area-wrappper .receive-msg {
  border-radius: 0.5rem;
  min-height: 9rem;
  max-width: 36rem;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: transparent;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-area-wrappper .receive-msg img {
  margin-bottom: 10px;
  width: 106px;
}
.editor-area-wrappper .receive-msg p {
  color: var(--base-color-2);
  line-height: 1.3;
  font-size: 1.1rem;
  font-weight: 600;
  max-width: 200px;
  margin: auto;
}
.editor-area-wrappper .embosser.receive-msg p {
  line-height: 1.3;
  font-size: 1.4rem;
  margin-top: 1.5rem;
}

.editor-area-wrappper .embosser.receive-msg img {
  width: 100px;
}

.editor-area-wrappper .boxes.receive-msg p {
  line-height: 1.4;
  font-size: 1.6rem;
  max-width: 28rem;
}

.editor-area-wrappper .boxes.receive-msg img {
  width: 75px;
}

.editor-area-wrappper .hang-tag.receive-msg p {
  line-height: 1.3;
  font-size: 1.4rem;
  margin-top: 1.5rem;
}

.editor-area-wrappper .hang-tag.receive-msg.ractangle p {
  line-height: 1.4;
  font-size: 1.4rem;
  max-width: 10.5rem;
}

.editor-area-wrappper .hang-tag.receive-msg img {
  width: 80px;
}

.editor-area-wrappper .drawstring.receive-msg p {
  line-height: 1.4;
  font-size: 1.2rem;
  max-width: 18rem;
}

.editor-area-wrappper .receive-msg.small-cls {
  max-width: 190px;
  padding: 0px 10px 10px;
  min-height: 46px;
  margin-top: 20px;
  background-color: transparent;
}

@media (max-width: 768px) {

.editor-area-wrappper .receive-msg.small-cls {
    max-width: 160px
}
  }
.editor-area-wrappper .receive-msg.small-cls img {
  width: 36px;
}
.editor-area-wrappper .receive-msg.small-cls p {
  font-size: 0.8rem;
  line-height: 1.1;
  margin-top: 0;
  max-width: 110px;
}

.editor-area-wrappper .receive-msg.washi-tape img {
  width: 36px;
}
.editor-area-wrappper .receive-msg.washi-tape p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-top: 0;
  max-width: 30rem;
}

.notification-label {
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  font-size: 12px;
  padding: 5px;
  line-height: 1.4;
}

@media (max-width: 1100px) {
  .notification-label {
    font-size: 10px;
    padding: 10px;
  }
}

@media (max-width: 1023px) {
  .notification-label {
    font-size: 12px;
    padding: 10px;
  }
}


.notification-label .title-text {
  color: #fd516e;
  font-weight: 700;
  display: inline;
}

.notification-label span {
  text-decoration: underline;
  color: #fd516e;
  font-weight: 700;
  cursor: pointer;
}

.notification-label span:hover {
  text-decoration: none;
}


.joinButton {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  height: 50px;
  padding: 15px;
}
.joinButton:hover {
  background-color: var(--base-color-2);
  color: var(--white);
}


.new-image-gallery .image-gallery {
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
  }
  .new-image-gallery .image-gallery-content {
    height: 100%;
}
  /* Slides */
  .new-image-gallery .image-gallery-slides {
    position: relative;
    height: 100%;
    overflow: hidden;
}
  @media (min-width: 640px) {
    .new-image-gallery .image-gallery-slides {
        position: static;
        overflow: auto;
    }
}
  .new-image-gallery .image-gallery-slide {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100vw;
    width: 100%;
}
  .new-image-gallery .image-gallery-slide.center {
    position: relative;
}
  @media (min-width: 640px) {
    .new-image-gallery .image-gallery-slide.center {
        position: absolute;
    }
}
  @media (min-width: 1024px) {
  .new-image-gallery .image-gallery-slide {
        height: 65vw;
        max-height: 100%;
        padding-top: 56px;
        padding-bottom: 56px;
    }
    }
  .new-image-gallery.is-full-screen .image-gallery-slide {
    height: 100vh;
}
  @media (min-width: 1024px) {
  .new-image-gallery.is-full-screen .image-gallery-slide {
        height: 100vh;
        max-height: 100vh;
    }
    }
  .new-image-gallery .video-wrapper {
    position: relative;
    background-color: transparent;
  }
  @media (min-width: 1024px) {
  .new-image-gallery .video-wrapper {
        height: 65vw;
        max-height: 100%;
    } 
    }
  .new-image-gallery .image-gallery-slide-wrapper {
    margin-bottom: 0px;
    height: 100%;
}
  .new-image-gallery .image-gallery-swipe {
    height: 100%;
    max-height: 90vh;
}
  .new-image-gallery.is-full-screen .image-gallery-swipe {
    max-height: 100vh;
}
  /* Bullets */
  .new-image-gallery .image-gallery-bullets {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    position: absolute;
    bottom: 30px;
    
    z-index: 4; 
  }
  .new-image-gallery .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .new-image-gallery .image-gallery-bullets .image-gallery-bullet {
    background-color: var(--black-40);
    height: 3px;
    margin: 0 2px;
    appearance: none;
    cursor: pointer;
    display: inline-block;
    outline: none;
    transition: background .2s ease-out;
}
  .new-image-gallery .image-gallery-bullets .image-gallery-bullet:focus,
  .new-image-gallery .image-gallery-bullets .image-gallery-bullet:hover {
    background-color: var(--black-80);
}
  .new-image-gallery .image-gallery-bullets .image-gallery-bullet.active {
    background-color: var(--black-80);
}

